<template>
  <div class="Wagepar">
    <div class="top-titles">
      <div class="title">
          <span>佣金工资待发放</span>/工资确认
      </div>
    </div>

    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
          <div class="particulars-list" ><span class="pl-tltle">会员名称:</span><span class="pl-content">{{listpar.wk_name}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">手机号:</span><span class="pl-content">{{listpar.wkbu_mobile}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">下单时间:</span><span class="pl-content">{{listpar.enroll_time}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">开工时间:</span><span class="pl-content">{{listpar.begin_datetime}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">招聘方:</span><span class="pl-content">{{listpar.company_name}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">订单状态:</span><span class="pl-content wage">{{listpar.status_name}}</span></div>
          <!-- <div class="particulars-list" ><span class="pl-tltle">工资:</span><span class="pl-content">{{listpar.salary}}</span></div> -->
          <div class="particulars-list" ><span class="pl-tltle">工时:</span><span class="pl-content">{{listpar.wkhours_sum}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">佣金:</span><span class="pl-content">{{listpar.commissionper}}</span></div>
         <div class="particulars-list" ><span class="pl-tltle">总佣金:</span><span class="pl-content">{{listpar.commission_sum}}</span></div>
      </div>

        <div class="wagepar-affirm">
          <div>
           <el-button type="success"  v-if="classactive == 0?true:false" @click="yijianyongjin()" class="biy-icon">发放佣金</el-button>
          </div>
          <div>
           <el-button type="primary"  v-if="classactive == 1?true:false" @click="yijiangongzi()" class="biy-icon">发放工资</el-button>
          </div>
      </div>

    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
        listpar:{},
        wkhours_sum:this.$route.query.wkhours_sum,
        commissionper:this.$route.query.commissionper,
        classactive:this.$route.query.estimate
    };
  },

  created(){
    this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'TodoWorkEntity',
        handleMode:'fetchWorkInfo',
        workId:this.$route.query.id,
        })
      
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
      },


          //弹窗
      yijianyongjin(){
          this.$confirm('是否确认发放佣金+总佣金？', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.yijianyongjins()
          }).catch(action => {
            console.log("取消");
          })
      },
    // 发放佣金
  async yijianyongjins(){
    var targets = this.listpar.workid.split()
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'TodoWorkEntity',
        handleMode:'dispatchMoney',
        catalog:'1',
        target:targets
        })
          if (res.ErrCode == "BalanceNotEnough") {
               this.$alert('余额不足请充值', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
              this.getSong()
          }
        });
        }else{
        if(this.$common.verifyAjaxResult(res,false)==false) return;
                this.$alert('发放成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/wage`});
          }
        });

        }
    },
        //弹窗
      yijiangongzi(){
          this.$confirm('是否确认发放工资？', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.yijiangongzis()
          }).catch(action => {
            console.log("取消");
          })
      },
    // 发放工资
  async  yijiangongzis(){
     var targets = this.listpar.workid.split()
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'TodoWorkEntity',
        handleMode:'dispatchMoney',
        catalog:'0',
       target:targets
        })
    

        if(this.$common.verifyAjaxResult(res,false)==false) return;
                this.$alert('发放成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
              this.$router.push({ path: `/home/wage`});
          }
        });
  },
  },
  computed: {},
};
</script>


<style  scoped>
/* 待审核商家按钮 */
.wagepar-affirm{
    height: 70px;
    line-height: 70px;
    width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding: 50px 0 200px 0;
}
.wagepar-affirm .biy-icon{
    width: 200px;
    border-radius: 10px;
    background-color: #01847F;
}
.pl-content.wage{
  font-size: 16px;
  color: rgb(255, 187, 0);
}
@import "../../assets/css/parstyle.css";

</style>